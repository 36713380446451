<!-- 个人信息 -->
<template>
  <div class="MyInfo">
    <div class="c_cell">
      <van-cell
        title="昵称"
        center
        is-link
        :value="nickName"
        :to="{ name: 'ModifyNickName', query: { nickName: nickName } }"
      />
      <van-cell
        title="性别"
        center
        is-link
        :value="sex"
        @click="showSex = true"
      />
      <van-cell
        title="生日"
        center
        is-link
        :value="birthday"
        @click="isShowDate = true"
      />
    </div>
    <!-- 生日选择-年月日 -->
    <van-popup
      v-model="isShowDate"
      round
      position="bottom"
      :style="{ height: '45%' }"
    >
      <van-datetime-picker
        class="c_van_picker"
        v-model="currentDate"
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @confirm="confirm"
        @cancel="isShowDate = false"
      />
    </van-popup>
    <!-- 性别选择 -->
    <van-action-sheet
      class="c_van_popup"
      v-model="showSex"
      :actions="actions"
      close-on-click-action
      @select="onSelect"
    />
  </div>
</template>

<script>
import { getUserInfo, updateUserInfo } from "@/api/api_personal.js";
export default {
  name: "MyInfo",
  data() {
    return {
      nickName: "",
      sex: "",
      birthday: "",
      showSex: false,
      actions: [
        { name: "男", code: 0 },
        { name: "女", code: 1 },
        { name: "取消", code: 2 }
      ],
      isShowDate: false,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      currentDate: new Date()
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.loginInfo;
    }
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    // 获取基本信息
    getUserInfo() {
      getUserInfo(
        { id: this.userInfo.userId },
        { headers: { token: this.userInfo.token } }
      ).then(res => {
        if (res.code === 0) {
          const info = res.data.info;
          this.nickName = info.nickName;
          this.userInfo.userName = this.nickName;
          this.$store.commit("setLoginInfo", this.userInfo);
          if (info.birthday) {
            this.birthday = this.$tools.formatDate(info.birthday);
            this.currentDate = new Date(this.birthday);
          }
          const ls = this.actions.filter(item => {
            return item.code == info.userSex;
          });
          this.sex = ls[0] && ls[0].name;
        }
      });
    },
    // 性别选择
    onSelect(item) {
      if (item.name === "取消") {
        this.showSex = false;
      } else {
        this.sex = item.name;
        this.update({ userSex: item.code, userId: this.userInfo.userId });
      }
    },
    // 生日选择
    confirm() {
      this.update(
        {
          birthday: new Date(this.currentDate).getTime(),
          userId: this.userInfo.userId
        },
        () => {
          this.birthday = this.$tools.formatDate(this.currentDate);
          this.isShowDate = false;
        }
      );
    },
    // 更新用户信息
    update(param, callBack) {
      updateUserInfo(param, { headers: { token: this.userInfo.token } }).then(
        res => {
          if (res.code == 0) {
            callBack && callBack();
          }
        }
      );
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      }
      return val;
    }
  }
};
</script>
<style lang="scss" scoped>
.MyInfo {
  height: 100%;
  background-image: linear-gradient(180deg, #ffffff 0%, #f3f4f5 33%);
  .c_cell {
    background: #ffffff;
    border-radius: 0.08rem;
    padding: 0 0.2rem;
    margin: 0.2rem 0.18rem;
    .van-cell__value {
      min-width: 70%;
    }
  }
}
</style>
